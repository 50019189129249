import {useEffect, useState} from "react";
import {DSYForm, DSYTable} from "../../styles/GlobalStyles";
import imageCompression from "browser-image-compression";
import {fetchSponsors} from "../../services/apiSponsors";
import useOnceEffect from "../../hooks/userOnceEffect";
import {DSYCampaignActionTypes, DSYPlatforms, friendlyPlatformImage, K_HIDE_SNAPCHAT} from "../../config/consts";
import {fetchCampaignById, updateCampaign} from "../../services/apiCampaignsService";
import {CircularProgress} from "@mui/material";
import {titleCase} from "../../components/Utils";


export function CampaignsEditPage({campaign, onCloseCallback, onUpdateItem}: {
    campaign: any,
    onCloseCallback: Function,
    onUpdateItem: Function
}) {

    const [sponsors, setSponsors] = useState<any>([]);
    const [didLoadData, setDidLoadData] = useState(false);
    const [values, setValues] = useState<any>({});
    const [didFetchFromServer, setDidFetchFromServer] = useState(false);
    const [savingData, setSavingData] = useState(false);

    let sectionsToEdit = [
        {'title': 'Name', 'input_type': 'text', 'value': '', 'name': 'name'},
        {'title': 'Internal Name', 'input_type': 'text', 'value': '', 'name': 'internal_name'},
        {'title': 'Platform', 'input_type': 'radio', 'value': '', 'name': 'platforms_id'},
        {'title': 'URL (Link to Post)', 'input_type': 'text', 'value': '', 'name': 'url'},
        {'title': 'Action/Reward', 'input_type': 'select', 'value': '', 'name': 'single_action_kind'},
        {'title': 'Extra Options', 'input_type': 'custom', 'value': '', 'name': 'extra_boost_options'},
        {'title': 'Image', 'input_type': 'file', 'accept_types': 'image/*', 'value': '', 'name': 'image'},

        {'title': 'Total Budget', 'input_type': 'number', 'value': '', 'name': 'purse'},
        {'title': 'Reward: Cash', 'input_type': 'number', 'value': '', 'name': 'cash_value'},

        {'title': 'Sponsor', 'input_type': 'select', 'value': '', 'name': 'sponsor.uuid'},

        {'title': 'Start Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_start_EST'},
        {'title': 'End Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_end_EST'},
        {'title': 'Active', 'input_type': 'checkbox', 'value': 'checked', 'name': 'active'},
        {
            'title': 'Only Visible to Internal Team',
            'input_type': 'checkbox',
            'value': 'checked',
            'name': 'only_visible_to_team'
        },
    ]
    const isNewItem = campaign.hasOwnProperty('uuid') && campaign.uuid === 'new';

    const getUpdatedCampaign = async (uuid: string) => {
        try {
            const data = await fetchCampaignById(uuid);

            if (data.hasOwnProperty('item')) {
                let item = data['item'];
                //update the campaign object with the new data
                campaign = {...campaign, ...item};
                onUpdateItem(campaign);
            }

        } catch (error) {
            console.error('Error fetching :', error);
        } finally {
            setDidFetchFromServer(true);
        }
    };

    const getSponsors = async () => {
        try {
            const data = await fetchSponsors();
            setSponsors([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                //sort by name
                items.sort((a: any, b: any) => {
                    return a.name.localeCompare(b.name);
                });
                //insert a blank option
                items.unshift({'uuid': '', 'name': 'Select Sponsor...'});

                setSponsors(items);
            }

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useOnceEffect(() => {
        if (isNewItem) {
            let b2 = structuredClone(campaign);
            setDidFetchFromServer(true);
        } else {
            getUpdatedCampaign(campaign.uuid);
        }
        getSponsors();

    });

    useEffect(() => {
        let data: any = {};

        sectionsToEdit.forEach((section) => {
            //if name has a dot, it's a nested object
            if (section.name.includes('.')) {
                let parts = section.name.split('.');
                let first = parts[0];
                let second = parts[1];
                if (campaign[first] != null) {
                    data[first + '.' + second] = campaign[first][second];
                }
            } else {
                data[section.name] = campaign[section.name];

            }
        });
        //add assets.videos_download
        if (campaign.assets && campaign.assets.videos && campaign.assets.videos.download) {
            data['videos_download'] = campaign.assets.videos.download;
        }
        if (campaign.assets && campaign.assets.images && campaign.assets.images.download) {
            data['images_download'] = campaign.assets.images.download;
        }

        setValues(data);
        setDidLoadData(true);

    }, [didFetchFromServer]);


    const handleCancel = (e: any) => {
        e.preventDefault();
        onCloseCallback();
    }

    const handleSave = async (e: any) => {
        e.preventDefault();


        let selectedPlatform = parseInt(values.platforms_id, 10);
        //validation
        //if platform_id == 1 (tiktok), url must start with https://www.tiktok.com/@ , if https://vm.tiktok.com/ , show error about expected url


        if (values.url != null && values.url.toString().trim().length > 4) {
            if (selectedPlatform === 1 && !values.url.startsWith('https://www.tiktok.com/@')) {
                //if empty, it's okay
                //try to get the full url from a redirect


                if (values.url.toString().trim() === '') {
                } else {
                    alert('TikTok URL must start with https://www.tiktok.com/@ ... Paste the url in a browser to get the full url');
                    return;
                }
            }
            //reg is https?://(www.)?instagram.com/
            if (selectedPlatform === 2 && !values.url.match(/https?:\/\/(www.)?instagram.com\//)) {
                if (values.url.toString().trim() === '') {
                } else {
                    alert('Instagram URL must start with https://www.instagram.com/ or https://instagram.com/');
                    return;
                }

            }
        }


        let sendValues = {...values};
        sendValues.uuid = campaign.uuid;
        console.log(sendValues);

        setSavingData(true);


        let formData = new FormData();
        let keysAsFiles = ['videos_download'];

        // Append all non-file form values to FormData
        Object.entries(sendValues).forEach(([key, value]) => {
            if (!keysAsFiles.includes(key)) {

                //append objects as JSON
                if (typeof value === 'object') {
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value as string);
                }
            }
        });

        // Append the video file if present
        for (let key of keysAsFiles) {
            if (values[key]) {
                formData.append(key, values[key]);
            }
        }

        console.log("Submitting FormData:");
        // @ts-ignore
        for (let [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        try {
            // Send formData instead of JSON
            const result = await updateCampaign(campaign.uuid, formData);
            console.log("Response:", result);
            if (result.status === 'error') {
                alert(result.message);
            } else {
                campaign = {...campaign, ...values};
                onUpdateItem(campaign);
                if (isNewItem) {
                    onCloseCallback({'reload': true});
                } else {
                    const data = await fetchCampaignById(campaign.uuid);
                    if (data.hasOwnProperty('item')) {
                        let item = data['item'];
                        //update the campaign object with the new data
                        campaign = {...campaign, ...item};
                        onUpdateItem(campaign);
                    }
                    onCloseCallback();
                }
            }
        } catch (error) {

        } finally {
            setSavingData(false);

        }
    }

    if (!didLoadData) {
        return <>
            Loading...
        </>
    }


    if (!didFetchFromServer) {
        return <>
            Loading...</>
    }

    return (
        <div>
            {savingData &&
                <div style={{
                    display: 'flex',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    color: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 24,
                    height: '100vh',
                    width: '100vw',
                    zIndex: 1000,
                }}>
                    Saving
                    <CircularProgress
                        color="inherit"
                        style={{
                            marginLeft: 20
                        }}
                        size={20}/>

                </div>
            }
            <div style={{
                display: 'inline-flex',
                gap: 20,
                alignItems: 'center',
                marginBottom: 20
            }}>
                {sectionCoverImage(values, setValues)}
                { //if snapchat is selected
                    values.platforms_id === DSYPlatforms.SNAPCHAT ?
                        sectionSnapchatAssets(campaign, values, setValues)
                        : sectionPlayableAssets(campaign, values, setValues)
                }

            </div>
            <h2>Edit </h2>
            <DSYForm onSubmit={handleSave}>
                {/*<textarea value={JSON.stringify(values, null, 2)} readOnly={true} style={{*/}
                {/*    width: '100%',*/}
                {/*    height: 200*/}
                {/*}}/>*/}
                <DSYTable>
                    {sectionsToEdit.map((section, index) => {
                        let inner = null;
                        if (section.input_type === 'checkbox') {
                            inner = <input type={section.input_type} name={section.name}
                                           checked={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.checked})
                                           }}/>

                        } else if (section.input_type === 'file') {
                            return null;
                        } else if (section.input_type === 'custom') {
                            if (section.name === 'extra_boost_options') {


                                //if instagram and share to story
                                if (!(values.platforms_id === DSYPlatforms.INSTAGRAM && values.single_action_kind === DSYCampaignActionTypes.REPOST)) {
                                    return null;
                                }

                                const nameOfLinkHandleHashtag = 'link_handle_hashtag';
                                //if values do not have link_handle_hashtag set, set it
                                if (!values[section.name]) {
                                    values[section.name] = {
                                        'link_handle_hashtag': {}
                                    };
                                }
                                //if values[section.name][nameOfLinkHandleHashtag] is not an object, set it
                                if (typeof values[section.name][nameOfLinkHandleHashtag] !== 'object') {
                                    values[section.name][nameOfLinkHandleHashtag] = {};
                                }
                                //if isarray, set it to object
                                if (Array.isArray(values[section.name][nameOfLinkHandleHashtag])) {
                                    values[section.name][nameOfLinkHandleHashtag] = {};
                                }

                                let possibleOptions = ['link', 'handle', 'hashtag'];

                                inner = <>

                                    <div
                                        style={{
                                            padding: 10,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1
                                        }}
                                    ><span
                                        style={{
                                            color: 'gray',
                                            fontSize: 12
                                        }}
                                    >Extra: Share to Story with Link (Optional, only one for now)</span>
                                        <table>
                                            <thead>
                                            </thead>
                                            <tbody>
                                            {possibleOptions.map((option, index) => {

                                                let thisType = option;
                                                let thisObject = values[section.name][nameOfLinkHandleHashtag][thisType] ?? {};
                                                let contentToUse = thisObject['content'] ?? '';

                                                return <tr key={index}>
                                                    <td>
                                                        {titleCase(option)} :
                                                    </td>
                                                    <td>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                gap: 10,
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <input type={'text'} value={contentToUse}
                                                                   onChange={(e) => {
                                                                       let newValues = {...values};
                                                                       if (newValues[section.name][nameOfLinkHandleHashtag][thisType] == null) {
                                                                           newValues[section.name][nameOfLinkHandleHashtag][thisType] = {};
                                                                       }
                                                                       newValues[section.name][nameOfLinkHandleHashtag][thisType].content = e.target.value;
                                                                       setValues(newValues);

                                                                   }}/>
                                                        </div>
                                                    </td>

                                                </tr>

                                            })}
                                            {/*<tr>*/}
                                            {/*    <td>*/}
                                            {/*        <select name={nameOfLinkHandleHashtag} value={selectedOption}*/}
                                            {/*                onChange={(e) => {*/}
                                            {/*                    let newValues = {...values};*/}
                                            {/*                    newValues[section.name][nameOfLinkHandleHashtag]['type'] = e.target.value;*/}
                                            {/*                    setValues(newValues);*/}
                                            {/*                }}>*/}
                                            {/*            {possibleOptions.map((option, index) => {*/}
                                            {/*                let selected = values[section.name] === option;*/}
                                            {/*                return <option key={index}*/}
                                            {/*                               selected={selected}*/}
                                            {/*                               value={option}>{option}</option>*/}
                                            {/*            })}*/}
                                            {/*        </select>*/}
                                            {/*    </td>*/}
                                            {/*    {thisType === 'none' ? null :*/}
                                            {/*        <td>*/}
                                            {/*            <div*/}
                                            {/*                style={{*/}
                                            {/*                    display: 'flex',*/}
                                            {/*                    gap: 10,*/}
                                            {/*                    alignItems: 'center'*/}
                                            {/*                }}*/}
                                            {/*            >*/}
                                            {/*                <input type={'text'} value={contentToUse}*/}
                                            {/*                       onChange={(e) => {*/}
                                            {/*                           let newValues = {...values};*/}
                                            {/*                           newValues[section.name][nameOfLinkHandleHashtag].content = e.target.value;*/}
                                            {/*                           setValues(newValues);*/}

                                            {/*                       }}/>*/}
                                            {/*                /!*<input type={'number'} value={cashToUse}*!/*/}
                                            {/*                /!*       onChange={(e) => {*!/*/}
                                            {/*                /!*           let newValues = {...values};*!/*/}
                                            {/*                /!*           newValues[section.name][option].cash = e.target.value;*!/*/}
                                            {/*                /!*           setValues(newValues);*!/*/}
                                            {/*                /!*       }}/>*!/*/}
                                            {/*            </div>*/}
                                            {/*        </td>*/}
                                            {/*    }*/}
                                            {/*</tr>*/}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            } else {
                                return null;
                            }
                        } else if (section.input_type === 'select') {


                            if (section.name === 'single_action_kind') {

                                if (values.platforms_id === DSYPlatforms.SNAPCHAT) {
                                    //if value is not set, or if it's not snap_share_assets_with_link, set it

                                    if (values[section.name] === '' || values[section.name] !== DSYCampaignActionTypes.SNAP_SHARE_ASSETS_WITH_LINK) {
                                        setValues({
                                            ...values,
                                            [section.name]: DSYCampaignActionTypes.SNAP_SHARE_ASSETS_WITH_LINK
                                        })
                                    }
                                } else {
                                    //if value is not set or value is snap_share_assets_with_link, set it to like_and_comment
                                    if (values[section.name] === '' || values[section.name] === DSYCampaignActionTypes.SNAP_SHARE_ASSETS_WITH_LINK) {
                                        setValues({...values, [section.name]: DSYCampaignActionTypes.LIKE_AND_COMMENT})
                                    }
                                }
                                let options =


                                    values.platforms_id === DSYPlatforms.SNAPCHAT ? [
                                            {
                                                'title': 'Snap: Share Assets and Link',
                                                'value': DSYCampaignActionTypes.SNAP_SHARE_ASSETS_WITH_LINK
                                            }] :
                                        [
                                            {
                                                'title': 'Like & Comment',
                                                'value': DSYCampaignActionTypes.LIKE_AND_COMMENT
                                            },
                                            {'title': 'Share to Story', 'value': DSYCampaignActionTypes.REPOST},
                                            {
                                                'title': 'Repost',
                                                'value': DSYCampaignActionTypes.REPOST_ON_ACCOUNT_PAGE
                                            },

                                        ];
                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option, index) => {
                                            let selected = values[section.name] === option.value;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.value}>{option.title}</option>
                                        })}
                                    </select>
                                </>
                            } else if (section.name === 'sponsor.uuid') {
                                let options = sponsors;
                                //insert a blank option

                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option: any, index: number) => {
                                            let selected = values[section.name] === option.uuid;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.uuid}>{option.name}</option>
                                        })}
                                    </select>
                                </>
                            }
                        } else if (section.input_type === 'radio') {

                            if (section.name === 'platforms_id') {
                                let options =
                                    K_HIDE_SNAPCHAT ? [
                                            {'title': 'TikTok', 'value': DSYPlatforms.TIKTOK},
                                            {'title': 'Instagram', 'value': DSYPlatforms.INSTAGRAM},
                                        ] :
                                        [
                                            {'title': 'TikTok', 'value': DSYPlatforms.TIKTOK},
                                            {'title': 'Instagram', 'value': DSYPlatforms.INSTAGRAM},
                                            {'title': 'Snapchat', 'value': DSYPlatforms.SNAPCHAT},
                                        ];
                                inner = <>
                                    <div style={{
                                        display: 'flex',
                                        padding: 10,
                                        alignItems: 'center',
                                        gap: 30
                                    }}>
                                        {options.map((option, index) => {
                                            let selected = parseInt(values[section.name], 10) === option.value;
                                            return <div


                                                onClick={() => {
                                                    setValues({
                                                        ...values,
                                                        [section.name]: option.value
                                                    })
                                                }}
                                                key={index} style={{
                                                display: 'flex',
                                                cursor: 'pointer',
                                                gap: 10,
                                                alignItems: 'center',
                                            }}>

                                                <input type={section.input_type} name={section.name}
                                                       value={option.value}
                                                       checked={selected}
                                                       onChange={(e) => {
                                                           setValues({
                                                               ...values,
                                                               [section.name]: parseInt(e.target.value, 10)
                                                           })
                                                       }}/>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 3
                                                }}>
                                                    <img src={friendlyPlatformImage(option.value)}
                                                         width={20}
                                                         height={20}/>
                                                    {option.title}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </>
                            } else {

                                inner = <input type={section.input_type} name={section.name}
                                               value={values[section.name]}
                                               onChange={(e) => {
                                                   setValues({...values, [section.name]: e.target.value})
                                               }}/>
                            }
                        } else {


                            inner = <input type={section.input_type} name={section.name}
                                           value={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.value})
                                           }}/>
                        }


                        let titleToUse = section.title;
                        if (values.platforms_id === DSYPlatforms.SNAPCHAT && section.name === 'url') {
                            titleToUse = 'Redirect URL';
                        }

                        return (
                            <tr key={index}>
                                <td>{titleToUse}</td>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        );
                    })}
                </DSYTable>
                <div className={'cta-area'}>
                    <a onClick={handleCancel}>Cancel</a>
                    <button type="submit">Submit</button>
                </div>
            </DSYForm>
        </div>
    );
}


function sectionCoverImage(values: any, setValues: any) {

    const handleImageChange = async (e: any) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {


            const options = {
                maxSizeMB: 1, // Maximum file size (in MB)
                maxWidthOrHeight: 1200, // Maximum dimensions
                // onProgress: (e) => console.log(e)
            };
            try {

                const compressedFile = await imageCompression(file, options);
                if (compressedFile) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setValues({...values, 'image': reader.result});
                    };
                    reader.readAsDataURL(compressedFile);

                } else {
                }
            } catch (error) {
                console.error(error);

            }
        }
    };

    return <div
        style={{
            border: '2px solid skyblue',
            borderRadius: '14px',
            padding: 20,
            display: 'inline-flex',
            flexDirection: 'column',

            gap: 10,
        }}
    ><h2>Cover Image</h2>
        <img src={values.image}
             width={80} style={{
            objectFit: 'cover',
            borderRadius: '5%',
            aspectRatio: 0.46153846

        }}/>

        <input type={'file'} accept={'image/*'} onChange={handleImageChange}/>
    </div>
}

function sectionPlayableAssets(campaign: any, values: any, setValues: any) {

    return <>

        <div style={{
            border: '2px solid skyblue',
            borderRadius: '14px',
            display: 'flex',
            padding: 20,
            flexDirection: 'column'
        }}>
            <h2>Playable Assets</h2>
            <div
                style={
                    {
                        display: 'flex',
                        gap: 20,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }
                }>

                {campaign.playable_video_url != null && campaign.playable_video_url.length > 0 &&
                    <video
                        controls
                        height="300"
                        style={{
                            objectFit: 'scale-down',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            aspectRatio: 9 / 16
                        }}
                    >
                        <source src={campaign
                            .playable_video_url} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                }
                {campaign['playable_gif_url'] && <img
                    height={200}
                    style={{
                        objectFit: 'scale-down',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                    }}
                    src={campaign['playable_gif_url']}/>}
            </div>
        </div>
    </>
}

function sectionSnapchatAssets(campaign: any, values: any, setValues: any) {

    const handleDownloadVideoChange = async (e: any) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('video/')) {
            setValues({...values, 'videos_download': file});
        }
    };


    const handleDownloadImageChange = async (e: any) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {


            const options = {
                maxSizeMB: 1, // Maximum file size (in MB)
                maxWidthOrHeight: 1200, // Maximum dimensions
                // onProgress: (e) => console.log(e)
            };
            try {

                const compressedFile = await imageCompression(file, options);
                if (compressedFile) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setValues({...values, 'images_download': reader.result});
                    };
                    reader.readAsDataURL(compressedFile);

                } else {
                }
            } catch (error) {
                console.error(error);

            }
        }
    };


    return <>
        <div style={{
            display: 'inline-flex',
            maxWidth: 400,
            flexDirection: 'column',
            gap: 10,
            border: '2px solid orange',
            borderRadius: '14px',
            padding: 20,
            fontSize: 12
        }}>
            <h3>2 Assets</h3>
            <p>
                Snapchat campaigns require 2 assets: a video and an image.
            </p>
            <div style={{
                display: 'flex',
                gap: 20,
                flexDirection: 'row'
            }}>
                <div>
                    <h3>Video</h3>
                    <input type={'file'} accept={'video/*'} onChange={handleDownloadVideoChange}/>
                    {values.videos_download && <>
                        <video
                            controls
                            height="200"
                            style={{
                                objectFit: 'scale-down',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                aspectRatio: 9 / 16
                            }}
                        >
                            <source src={
                                (typeof values.videos_download === 'string') ? values.videos_download :
                                    URL.createObjectURL(values.videos_download)
                            } type="video/mp4"/>

                            Your browser does not support the video tag.
                        </video>
                        <button onClick={() => {
                            setValues({...values, 'videos_download': null})
                        }}>Remove Video
                        </button>
                    </>}

                </div>
                <div>
                    <h3>Image</h3>
                    <input type={'file'} accept={'image/*'} onChange={handleDownloadImageChange}/>
                    {values.images_download && <>
                        <img src={values.images_download}
                             height={200} style={{
                            objectFit: 'cover',
                            borderRadius: '5%',
                            aspectRatio: 0.46153846

                        }}/>
                        <button onClick={() => {
                            setValues({...values, 'images_download': null})
                        }}>Remove Image
                        </button>
                    </>}
                </div>
            </div>
        </div>
    </>
}