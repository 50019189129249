import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, {useState} from "react";
import useOnceEffect from "../hooks/userOnceEffect";
import {DSYMainWrapper, DSYMainWrapperInner, DSYTable} from "../styles/GlobalStyles";
import {formatNumber, friendlyDate, sortItems} from "../components/Utils";
import {fetchBoostsForCampaignUUID, sendStripePaymentForBoost, updateBoostStatus} from "../services/apiBoosts";
import {useNavigate, useParams} from "react-router-dom";
import {DSYPlatforms, friendlyCampaignActionName, friendlyPlatformName} from "../config/consts";
import {renderTableHeader} from "./UsersPage";
import {CircularProgress} from "@mui/material";
import {DSYFinancialsWidget} from "../components/financials/DSYFinancialsWidget";
import toast, {Toaster} from "react-hot-toast";
import styled from "styled-components";
import {sendNotifyStripeSetupForRewardsNudge} from "../services/apiUsersService";
import {DollarSign, Mail, TriangleAlert} from "lucide-react";


const DivStripeErrorArea = styled.div`
    color: #990000;
    font-size: 12px;
    text-align: start;
    padding: 10px;
    border: 1px solid #990000;

    ul {
        margin: 10px 0 10px 20px;

        li {
            margin: 5px 0;
        }
    }

`;

export function BoostsDetailPage() {
    const [didLoad, setDidLoad] = useState(false);
    const [boosts, setBoosts] = useState<any>([]);
    const [campaign, setCampaign] = useState<any>(null);
    const [selectedBoost, setSelectedBoost] = useState<any>({});
    const [temporaryStatus, setTemporaryStatus] = useState<String>('');
    const [showDetailsForUUIDS, setShowDetailsForUUIDS] = useState<any>([]);
    const [editNotesForUUID, setEditNotesForUUID] = useState<any>(null);
    const [editNotesUpdtedText, setEditNotesUpdtedText] = useState<any>(null);


    const [sortBy, setSortBy] = useState('created');
    const navigate = useNavigate();
    const {campaignsUUID} = useParams();

    const getItems = async () => {
        try {
            const data = await fetchBoostsForCampaignUUID(campaignsUUID as string);
            setBoosts([]);
            setCampaign(null);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setBoosts(items);

            }
            if (data.hasOwnProperty('campaign')) {
                setCampaign(data['campaign']);
            }
            setDidLoad(true);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    useOnceEffect(() => {
        getItems();
    });


    const fullScreen = false;
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: object, reason: String) => {
        console.log("reason: " + reason);
        if (reason === 'backdropClick') {
            // return;
        }
        setOpen(false);
    };

    const hanleOnCloseRequest = (e: any) => {
        console.log("hanleOnCloseRequest");
        console.log(e);
        setOpen(false);
    }


    let onEditBoost = (item: any) => {
        setTemporaryStatus(item['status']);
        setSelectedBoost(item);
        setOpen(true);
        //reload page
        getItems();
    }


    const handleUpdateItem = async (item: any) => {

        let sendValues = {'uuid': item['uuid'], 'status': temporaryStatus};
        var result = await updateBoostStatus(item['uuid'], sendValues);
        console.log('saved', result);
        setOpen(false);
        if (result.status === 'error') {
            alert(result.message);
        } else {
            item['status'] = temporaryStatus;
            let index = boosts.findIndex((u: any) => u.uuid === item.uuid);
            if (index > -1) {
                let updated: any[] = [...boosts];
                updated[index] = item;
                setBoosts(updated);
            } else {
                //reload page
                getItems();
            }
        }
    }

    const handleUpdateItemNotes = async (item: any) => {
        let sendValues = {'uuid': item['uuid'], 'internal_notes': editNotesUpdtedText};
        await updateBoostStatus(item['uuid'], sendValues);
        item['internal_notes'] = editNotesUpdtedText;
        let index = boosts.findIndex((u: any) => u.uuid === item.uuid);
        if (index > -1) {
            let updated: any[] = [...boosts];
            updated[index] = item;
            setBoosts(updated);
        }
        setEditNotesForUUID(null);
        setEditNotesUpdtedText(null);

    }

    const filterItems = (search?: string) => {
        if (!search) {
            sortItems(sortBy, boosts);
            return;
        }
        let term = search.toLowerCase();
        let fieldsToSearch = ['name'];
        let filtered = boosts.filter((user: any) => {
            if (user != null) {
                let match = false;
                fieldsToSearch.forEach((field) => {
                    if (user[field]) {
                        if (user[field].toLowerCase().includes(term)) {
                            match = true;
                        }
                    }
                });
                if (match) {
                    return true;
                }
            }
            return false;
        });
        //sort
        sortItems(sortBy, filtered);

    }


    const handleSortByClick = (field: string) => {
        if (sortBy === field) {
            setSortBy(field + '_desc');
        } else {
            setSortBy(field);
        }
        filterItems();
    }

    const filteredForSection = (section: string) => {
        if (section === 'all') {
            return boosts;
        }
        return boosts.filter((item: any) => {
                //upcoming_live = active and date_start <= now && date_end >= now
                //completed = date_end >= now
                //internal_only = internal_only = 1
                let willReceiveReward = parseInt(item['will_receive_reward'], 10) === 1;
                if (section === 'free') {
                    return !willReceiveReward;
                } else if (section === 'approved') {
                    return willReceiveReward && item['status'] === 'approved';
                } else if (section === 'rewarded') {
                    return willReceiveReward && item['status'] === 'rewarded';
                } else if (section === 'pending') {
                    return willReceiveReward && item['status'] === 'pending';
                }

                return false;
            }
        );
    }

    const campaignHasComments = campaign && campaign['single_action_kind'] === 'like_and_comment';
    const campaignIsRepostShareToStory = campaign && campaign['single_action_kind'] === 'repost';
    const campaignIsRepostOnAccount = campaign && campaign['single_action_kind'] === 'repost_on_account_page';

    const isTikTok = campaign && campaign['platforms_id'] === DSYPlatforms.TIKTOK;
    const isInstagram = campaign && campaign['platforms_id'] === DSYPlatforms.INSTAGRAM;
    const isSnapchat = campaign && campaign['platforms_id'] === DSYPlatforms.SNAPCHAT;
    const showLinkTrackingShortcode = isSnapchat || (campaign && campaign['has_extra_boost_options'] != null && campaign['has_extra_boost_options'] === 1);

    const [listOfUUIDsSendingPayment, setListOfUUIDsSendingPayment] = useState<any>({});

    const handleSendPayment = async (item: any) => {
        // console.log('send payment', item);
        //
        //
        // setListOfUUIDsSendingPayment([...listOfUUIDsSendingPayment, item['uuid']]);
        // let sendValues = {'uuid': item['uuid'], 'status': 'rewarded'};
        // //await 2 seconds
        // var result = await sendPaypalPaymentForBoost(item['uuid'], sendValues);
        // setListOfUUIDsSendingPayment(listOfUUIDsSendingPayment.filter((x: any) => x !== item['uuid']));
        //
        //
        // console.log('saved', result);
        // // eslint-disable-next-line eqeqeq
        // if (result.status.toString() != 'success') {
        //     alert(result.error_title + ': ' + result.error_message);
        // } else {
        //     item['status'] = 'rewarded';
        //     let index = boosts.findIndex((u: any) => u.uuid === item.uuid);
        //     if (index > -1) {
        //         let updated: any[] = [...boosts];
        //         updated[index] = item;
        //         setBoosts(updated);
        //     } else {
        //         //reload page
        //         getItems();
        //     }
        // }

        //send payment
        //find the item table with this key and hide it
        //update the status to rewarded
    }

    const handleSendStripePaymentConfirm = async (item: any) => {
        setListOfUUIDsSendingPayment((prev: {}) => {
            return {...prev, [item['uuid']]: {status: 'confirm'}}
        });
    }

    const handleSendStripePaymentCancelConfirm = async (item: any) => {
        setListOfUUIDsSendingPayment((prev: {}) => {
            return {...prev, [item['uuid']]: {status: 'blank'}}
        });

        setListOfUUIDsSendingPayment((prev: any) => {
            delete prev[item['uuid']];
            return prev;
        });
    }

    const handleSendStripePayment = async (item: any) => {

        let t = toast.loading('Sending Payment...');
        setListOfUUIDsSendingPayment((prev: {}) => {
            return {...prev, [item['uuid']]: {status: 'sending'}}
        });
        let sendValues = {'uuid': item['uuid'], 'status': 'rewarded'};
        //await 2 seconds
        var result = await sendStripePaymentForBoost(item['uuid'], sendValues);
        setListOfUUIDsSendingPayment((prev: any) => {
            delete prev[item['uuid']];
            return prev;
        });
        toast.dismiss(t);

        // eslint-disable-next-line eqeqeq
        if (result.status.toString() != 'success') {
            // alert(result.error_title + ': ' + result.error_message);

            toast.error(result.error_title + ': ' + result.error_message);
            setListOfUUIDsSendingPayment((prev: {}) => {
                return {
                    ...prev, [item['uuid']]: {
                        status: 'error',
                        error: result.error_title + ': ' + result.error_message
                    }
                }
            });
        } else {
            toast.success('Payment Sent');
            item['status'] = 'rewarded';
            let index = boosts.findIndex((u: any) => u.uuid === item.uuid);
            if (index > -1) {
                let updated: any[] = [...boosts];
                updated[index] = item;
                setBoosts(updated);
            } else {
                //reload page
                getItems();
            }
        }

        //send payment
        //find the item table with this key and hide it
        //update the status to rewarded

    }

    //================================================================================================
    //               UI
    //================================================================================================


    const viewOnPlatform = () => {

        if (campaign && campaign['platforms_id']) {

            if (isSnapchat) {
                return <div>
                    Campaign link: <a href={campaign['url']} target={'_blank'}>{campaign['url']}</a>
                    <br/><br/>
                </div>
            }

            return <a style={{
                marginBottom: '20px',
                display: 'inline-flex',
                fontSize: '14px',
                color: 'blue',
            }} href={campaign['url']} target={'_blank'}>View
                on: {friendlyPlatformName(campaign['platforms_id'])}</a>
        }
        return null;
    }

    const shareLinkTotalsSummary = () => {

        if (campaign && showLinkTrackingShortcode) {

            let totalShortcodeVisits = 0;
            // eslint-disable-next-line array-callback-return
            boosts.map((item: any) => {
                totalShortcodeVisits += parseInt(item['tracking_shortcode_visits'], 10);
            });

            return <div style={{
                marginBottom: '20px',
                display: 'inline-flex',
                borderRadius: '25px',
                alignItems: 'center',
                lineHeight: '1',
                padding: '10px 20px 0px 20px',
                justifyContent: 'space-between',
                fontWeight: 'normal',
                fontSize: '14px',
                backgroundColor: '#C7EBF0',
            }}>
                <h3>Shortcode Visits: {formatNumber(totalShortcodeVisits)}</h3>
            </div>

        }
        return null;
    }

    const buildStripeLink = (item: any) => {
        if (item['stripe'] != null && item['stripe']['stripe_account_id'] != null) {
            let stripeAccountId = item['stripe']['stripe_account_id'];
            let url = `https://dashboard.stripe.com/connect/accounts/${stripeAccountId}/activity`;
            return <a
                style={{
                    color: '#5157F0',
                    textDecoration: 'underline',
                }}
                href={url} target={'_blank'}>{stripeAccountId}</a>;
        }
        return null;

    }

    const handleStripSendNudgeEmail = (item: any) => {

        let campaignUUID = item['campaigns_uuid'];
        let userUUID = item['users_uuid'];
        toast.promise(
            async () => {
                let result = await sendNotifyStripeSetupForRewardsNudge(userUUID, campaignUUID);
                if (result.hasOwnProperty('error')) {
                    return result.error;
                }
                return 'Email Sent';
            },
            {
                loading: 'Sending Email...',
                success: (data) => {
                    return data;
                },
                error: (err) => {
                    return err;
                },

            },
            {
                style: {
                    minWidth: '250px',
                    padding: '10px',
                    backgroundColor: '#333',
                    color: '#fff',
                }
            }
        );
    }

    function widgetStripeEmailNudge(item: any) {

        const noEmail = item['users_email'] == null || item['users_email'].length < 1;
        if (noEmail) {
            return <div
                style={{
                    backgroundColor: '#900',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    width: 'fit-content',
                    fontWeight: 'bold',
                    padding: '2px 6px',
                }}
            >
                <TriangleAlert/>
                User has no email on file</div>
        }

        return <>
            <button
                style={{
                    backgroundColor: '#FF101F33',
                    color: 'black',
                    padding: '2px 6px',
                    borderRadius: '5px',
                    fontWeight: 'bold',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'

                }}
                onClick={() => {
                    handleStripSendNudgeEmail(item);
                }}>

                <Mail/>Notify: Complete Stripe Setup
            </button>
        </>

    }

    const CountryFlag = ({code}: { code: string }) => (
        <><img
            src={`https://flagcdn.com/16x12/${code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/32x24/${code.toLowerCase()}.png 2x, https://flagcdn.com/48x36/${code.toLowerCase()}.png 3x`}
            width="16"
            height="12"
            alt="Ukraine">
        </img>
        </>
    );

    const widgetStripeUserCountry = (item: any) => {
        let userCountry = "";
        if (item['stripe'] != null && item['stripe']['country'] != null) {
            userCountry = item['stripe']['country'];
        }
        //if not US, show flag of country
        if (userCountry.length < 1) {
            return null;
        }
        return <div
            style={{
                backgroundColor: '#f0f0f0',
                color: '#333',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                padding: '2px 6px',
                borderRadius: '5px',
                fontWeight: 'bold',
            }}
        >
            {userCountry}
        </div>


    }
    const widgetStripeArea = (item: any, sectionSlug: string) => {
        let sectionIsFree = sectionSlug === 'free';
        let sectionIsApproved = sectionSlug === 'approved';
        let sectionIsRewarded = sectionSlug === 'rewarded';
        let sectionIsPending = sectionSlug === 'pending';
        let userCountry = "";

        let canSendPayment = false;
        let stripeAccountId = '';
        if (item['stripe'] != null && item['stripe']['stripe_account_id'] != null) {
            canSendPayment = item['stripe']['has_completed_transfer_setup'] === 1;
            stripeAccountId = item['stripe']['stripe_account_id'];
            userCountry = item['stripe']['country'];
        }
        if (!sectionIsApproved) {
            return canSendPayment ? <div>{buildStripeLink(item)}</div> : <div>---</div>;

        }

        let isSendingPayment = false;
        let isConfirmingPayment = false;
        let sendingPaymentError = null;
        if (listOfUUIDsSendingPayment.hasOwnProperty(item['uuid'])) {
            let sendingPaymentData = listOfUUIDsSendingPayment[item['uuid']];
            isSendingPayment = sendingPaymentData['status'] === 'sending';
            isConfirmingPayment = sendingPaymentData['status'] === 'confirm';
            sendingPaymentError = isSendingPayment && sendingPaymentData.hasOwnProperty('error') ? sendingPaymentData['error'].toString() : null;
        }

        const showSendButton = item['status'] === 'approved';

        if (isSendingPayment && sendingPaymentError) {
            isSendingPayment = false;
        }

        let hasStripeTransferId = item['stripe_transfers_id'] != null;

        if (item['stripe'] != null && item['stripe']['stripe_account_id'] != null) {
            const email = item['stripe']['email'];
            if (!canSendPayment) {
                let errors: any[] = [];
                if (item['stripe']['requirements_errors'] != null) {
                    for (let key in item['stripe']['requirements_errors']) {
                        try {
                            let e = item['stripe']['requirements_errors'][key] as any;
                            if (e != null) {

                                if (e.hasOwnProperty('reason')) {
                                    e = e['reason'];
                                    errors.push(e);
                                }
                            }
                        } catch (e) {

                        }
                    }

                }

                return <DivStripeErrorArea>
                    {widgetStripeUserCountry(item)}
                    {/*<button*/}
                    {/*    onClick={() => {*/}

                    {/*    }}*/}
                    {/*>Check Account*/}
                    {/*</button>*/}
                    <p>[ Incomplete ]<br/> {email}</p>
                    <div style={{
                        float: 'right',
                    }}>
                        {widgetStripeEmailNudge(item)}
                    </div>
                    <p>ID: {buildStripeLink(item)}</p>
                    {errors.length > 0 && <><br/><p>Errors:</p>
                        <ul>
                            {errors.map((e) => {
                                    return <li>{e}</li>
                                }
                            )}</ul>
                    </>}


                </DivStripeErrorArea>
            }

            if (!showSendButton) {
                return <div style={{
                    color: 'black',
                }}>
                    {hasStripeTransferId && <p>Payment Sent</p>}
                    <p>ID: {buildStripeLink(item)}</p>
                </div>
            }

            return <div style={{
                color: '#444',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
            }}>

                {isSendingPayment && <div>
                    <p>Sending Payment...</p>
                    <CircularProgress/>
                </div>}
                {hasStripeTransferId && <p>Payment Sent</p>}
                {isConfirmingPayment ? <div
                        style={{
                            backgroundColor: '#90EE9066',
                            border: '1px solid #90EE90',
                            padding: '10px',
                            borderRadius: '5px',
                            color: 'black',
                        }}>
                        <p>Are you sure?</p>
                        <div>
                            <button
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    fontWeight: 'bold',
                                    border: 'none',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                }}
                                onClick={() => {
                                    handleSendStripePaymentCancelConfirm(item);
                                }}>CANCEL
                            </button>
                            <button
                                style={{
                                    backgroundColor: 'lightgreen',
                                    color: 'black',
                                    padding: '5px 10px',
                                    borderRadius: '5px',
                                    fontWeight: 'bold',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    handleSendStripePayment(item);
                                }}>CONFIRM
                            </button>
                        </div>
                    </div> :
                    <>
                        {widgetStripeUserCountry(item)}
                        <button
                            style={{


                                backgroundColor: 'green',
                                color: 'white',
                                display: 'flex',
                                lineHeight: '0',
                                alignItems: 'center',
                                padding: '5px 6px',
                                borderRadius: '5px',
                                fontWeight: 'bold',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                handleSendStripePaymentConfirm(item);
                            }}><DollarSign size={20}/> Stripe Payout:  &nbsp;<strong>${campaign['cash_value']}</strong>
                        </button>
                    </>}
                <p>ID: {buildStripeLink(item)}</p>
                {sendingPaymentError && <p style={{
                    color: '#990000',
                }}>ERROR: {sendingPaymentError}</p>}


            </div>
        }
        return <div>
            {widgetStripeUserCountry(item)}
            {widgetStripeEmailNudge(item)}
        </div>
    }


    //================================================================================================
    //               MAIN
    //================================================================================================

    if (campaign == null) {
        return <DSYMainWrapper>
            <DSYMainWrapperInner>
                <Toaster/>
                <DSYFinancialsWidget/>
                <h2>Loading...</h2>
            </DSYMainWrapperInner>
        </DSYMainWrapper>
    }


    return (
        <DSYMainWrapper>
            <DSYMainWrapperInner>
                <Toaster/>
                <DSYFinancialsWidget/>
                <div
                    style={{
                        width: 'fit-content',
                        display: 'flex',
                        gap: '10px',
                    }}
                ><h2 style={{

                    marginBottom: '0px',
                }}>Boosts for: {<>{campaign['name']}</>}</h2>
                </div>
                <div style={{
                    marginBottom: '20px',
                    width: 'fit-content',
                    display: 'flex',
                    gap: '10px',
                    flexDirection: 'column',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #f0f0f0',
                }}>
                    {campaign['internal_name'] != null && campaign['internal_name'].length > 1 && campaign['internal_name'] != 'undefined' && campaign['internal_name'] != 'null' &&
                        <div style={{
                            fontSize: '13px',
                            color: '#333',
                            fontWeight: 'normal',
                        }}>Internal Name: {campaign['internal_name']}</div>}
                    <p style={{fontSize: '12px'}}>Boost
                        ID: {campaignsUUID}</p>
                </div>
                {campaign['platforms_id'] != null &&
                    <>
                        <div style={{
                            marginBottom: '20px',
                            display: 'inline-flex',
                            borderRadius: '25px',
                            alignItems: 'center',
                            lineHeight: '1',
                            padding: '10px 20px 0px 20px',
                            justifyContent: 'space-between',
                            backgroundColor: '#f0f0f0',
                        }}>
                            <h3>{friendlyPlatformName(campaign['platforms_id'])}: {campaign && <>{friendlyCampaignActionName(campaign['single_action_kind'])}</>}</h3>
                        </div>
                    </>
                }
                <br/>

                {shareLinkTotalsSummary()}
                {viewOnPlatform()}

                {(campaignIsRepostShareToStory) && <>
                    <div style={{
                        backgroundColor: '#87CEFA44',

                        padding: '10px',
                        borderRadius: '5px',
                        marginBottom: '20px',
                    }}>
                        <h4>Share To Story Campaign</h4>
                        <p>
                            Go to the User's profile and verify the post.
                            If the post is not there, the user will not receive the reward.
                        </p>
                    </div>
                </>}


                {open && <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    // fullWidth={true}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        <div>
                            <h3>Change Status</h3>
                            <br/>
                            <div style={{
                                display: 'flex',
                                gap: '10px',
                                flexDirection: 'column',
                                marginBottom: '20px',
                            }}>
                                {
                                    [
                                        {'title': 'Approved / Found', 'value': 'approved'},
                                        {'title': 'Rewarded / Paid', 'value': 'rewarded'},
                                        {'title': 'Pending', 'value': 'pending'},
                                        {'title': 'Rejected', 'value': 'rejected'},
                                    ].map((status) => {
                                            //return list of Radio buttons

                                            return (
                                                <>
                                                    <div key={status['value']} style={{
                                                        display: 'flex',
                                                        gap: '12px',
                                                        alignItems: 'center',
                                                        marginRight: '20px',
                                                        marginBottom: '10px',
                                                    }}>
                                                        <input type="radio" id={status['value']} name="status"
                                                               value={status['value']}
                                                               checked={temporaryStatus === status['value']}
                                                               onChange={(e) => {
                                                                   setTemporaryStatus(status['value']);
                                                               }}/>
                                                        <label htmlFor={status['value']}>{status['title']}</label>
                                                    </div>
                                                </>
                                            );
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <button onClick={() => {
                            handleUpdateItem(selectedBoost);

                        }}>Submit
                        </button>
                    </DialogContent>
                </Dialog>}
                {
                    [
                        {'title': 'Approved : Not Paid', 'slug': 'approved'},
                        {'title': 'Rewarded : Paid', 'slug': 'rewarded'},
                        {'title': 'Pending', 'slug': 'pending'},
                        {'title': 'Free Boosts', 'slug': 'free'},
                    ].map((section) => {

                        let itemsForSection = filteredForSection(section['slug']);
                        let isFree = section['slug'] === 'free';

                        if (itemsForSection.length === 0) {
                            return null;
                        }

                        let totalShortcodeVisits = 0;
                        if (showLinkTrackingShortcode) {
                            itemsForSection.map((item: any) => {
                                totalShortcodeVisits += parseInt(item['tracking_shortcode_visits'], 10);
                            });
                        }

                        return <div key={section['slug']}>
                            <h3>{section['title']} ( {itemsForSection.length} )</h3>
                            {showLinkTrackingShortcode &&
                                <div
                                    style={{
                                        marginBottom: '20px',
                                        display: 'inline-flex',
                                        borderRadius: '25px',
                                        alignItems: 'center',
                                        lineHeight: '1',
                                        padding: '10px',
                                        fontWeight: 'normal',
                                        fontSize: '12px',
                                        backgroundColor: '#C7EBF055',
                                    }}
                                >{section['title']} :: Shortcode Visits: {formatNumber(totalShortcodeVisits)}</div>}
                            <DSYTable
                                key={section['slug']}

                                style={{
                                    margin: '0',
                                    width: 'auto',
                                }}>
                                <thead>
                                <tr>{
                                    [
                                        {'title': 'Options', 'field': 'name', 'sort': false},
                                        {
                                            'title': 'Shortcode',
                                            'field': 'tracking_shortcode',
                                            'only_if': showLinkTrackingShortcode,
                                            'sort': true
                                        },
                                        {
                                            'title': 'Shortcode Visits',
                                            'field': 'tracking_shortcode_visits',
                                            'only_if': showLinkTrackingShortcode,
                                            'sort': true
                                        },
                                        {'title': 'First', 'field': 'users_first_name', 'sort': true},
                                        {'title': 'Last', 'field': 'users_last_name', 'sort': true},
                                        {'title': 'Username', 'field': 'users_handle_tiktok', 'sort': true},
                                        {'title': 'Change Status', 'field': 'status', 'sort': false},
                                        (campaignHasComments ? {
                                            'title': 'Comment Used',
                                            'field': 'comment.message',
                                            'sort': false
                                        } : null),
                                        {'title': 'Posted', 'field': 'created', 'sort': true},
                                        {'title': 'PayPal Details', 'field': 'x1', 'sort': false},
                                        {'title': 'Internal Notes', 'field': 'internal_notes', 'sort': false},
                                        {'title': 'Payment Issues', 'field': 'flagged_with_issue', 'sort': false},
                                        {
                                            'title': 'Stripe',
                                            'field': 'users_stripe_account_id',
                                            'sort': false
                                        },
                                        // {
                                        //     'title': 'Paypal ID',
                                        //     'field': 'users_paypal_account_id',
                                        //     'sort': true
                                        // },

                                        // {'title': 'Options', 'field': 'options', 'sort': false},
                                    ].map((headItem) => {

                                            if (headItem) {
                                                let onlyIf = headItem['only_if'] != null ? headItem['only_if'] : true;
                                                if (onlyIf != null && !onlyIf) {
                                                    return null;
                                                }
                                            }

                                            return renderTableHeader(headItem, sortBy, handleSortByClick);
                                        }
                                    )
                                }
                                </tr>
                                </thead>
                                <tbody>
                                {itemsForSection.map((item: any) => {

                                    let isEditingNotes = editNotesForUUID === item['uuid'];

                                    let isSendingPayment = false;

                                    let sendingPaymentError = null;
                                    if (listOfUUIDsSendingPayment.hasOwnProperty(item['uuid'])) {
                                        let sendingPaymentData = listOfUUIDsSendingPayment[item['uuid']];
                                        isSendingPayment = sendingPaymentData['status'] === 'sending';
                                        sendingPaymentError = isSendingPayment && sendingPaymentData.hasOwnProperty('error') ? sendingPaymentData['error'].toString() : null;
                                    }


                                    if (isSendingPayment && sendingPaymentError) {
                                        isSendingPayment = false;
                                    }
                                    let showPayoutDetails = item['status'] === 'approved' && showDetailsForUUIDS.includes(item['uuid']);

                                    let hasPaypalId = item['users_paypal_account_id'] != null && item['users_paypal_account_id'].length > 0;
                                    hasPaypalId = false;

                                    return (
                                        <tr key={item['uuid']} style={{
                                            opacity: isSendingPayment ? 0.5 : 1,
                                            pointerEvents: isSendingPayment ? 'none' : 'auto',
                                        }}>
                                            <td>

                                                {(campaignIsRepostShareToStory) && <>
                                                    <button
                                                        style={{
                                                            backgroundColor: '#87CEFA44',
                                                            color: 'black',
                                                            fontSize: '11px',
                                                            whiteSpace: 'nowrap',

                                                        }}
                                                        onClick={() => {
                                                            if (isInstagram) {
                                                                window.open('https://www.instagram.com/' + item['users_handle_instagram'], '_blank');
                                                            } else {
                                                                window.open('https://www.tiktok.com/@' + item['users_handle_tiktok'], '_blank');
                                                            }

                                                        }}>View Profile
                                                    </button>
                                                </>}
                                            </td>
                                            {showLinkTrackingShortcode && <td>{item['tracking_shortcode']}</td>}
                                            {showLinkTrackingShortcode && <td>{item['tracking_shortcode_visits']}</td>}
                                            <td style={{fontWeight: 'bold',}}>{item['users_first_name']}</td>
                                            <td style={{fontWeight: 'bold',}}>{item['users_last_name']}</td>
                                            <td>
                                                <ul style={{
                                                    margin: '0 0 0 20px',
                                                }}>
                                                    {isSnapchat ? <li>Snap: {item['users_handle_snapchat'] ?
                                                        <a href={'https://www.snapchat.com/add/' + item['users_handle_snapchat']}
                                                           referrerPolicy={'no-referrer'}
                                                           target={'_blank'}>{item['users_handle_snapchat']}</a> : '--'}</li> : <>

                                                        <li>TT: {item['users_handle_tiktok'] ?
                                                            <a href={'https://www.tiktok.com/@' + item['users_handle_tiktok']}
                                                               referrerPolicy={'no-referrer'}
                                                               target={'_blank'}>{item['users_handle_tiktok']}</a> : '--'}</li>
                                                        <li>IG: {item['users_handle_instagram'] ?
                                                            <a href={'https://www.instagram.com/' + item['users_handle_instagram']}
                                                               referrerPolicy={'no-referrer'}
                                                               target={'_blank'}>{item['users_handle_instagram']}</a> : '--'}</li>
                                                    </>}
                                                </ul>
                                            </td>
                                            <td>
                                                {isFree && false ? <></> :
                                                    <button
                                                        style={{
                                                            backgroundColor: 'blue',
                                                            color: 'white',
                                                            padding: '5px 10px',
                                                            borderRadius: '5px',
                                                            fontWeight: 'bold',
                                                            cursor: 'pointer',
                                                            fontSize: '11px',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                        onClick={() => {
                                                            onEditBoost(item);
                                                        }}>
                                                        Change Status
                                                    </button>}
                                            </td>
                                            {campaignHasComments && <td style={{
                                                maxWidth: '200px',
                                                minWidth: '200px',
                                                whiteSpace: 'wrap',
                                                fontSize: '11px',
                                                wordBreak: 'break-all',
                                            }}>{item['comment'] && item['comment']['message']}</td>}
                                            <td style={{
                                                maxWidth: '200px',
                                                minWidth: '200px',
                                                whiteSpace: 'wrap',
                                                fontSize: '11px',
                                                wordBreak: 'break-all',
                                            }}>
                                                {friendlyDate(item['created'], 'LLL')}</td>
                                            <td>
                                                {item['status'] === 'approved' &&
                                                    <button onClick={() => {
                                                        //toggle
                                                        if (showDetailsForUUIDS.includes(item['uuid'])) {
                                                            setShowDetailsForUUIDS(showDetailsForUUIDS.filter((x: any) => x !== item['uuid']));
                                                            return;
                                                        }
                                                        setShowDetailsForUUIDS([...showDetailsForUUIDS, item['uuid']]);
                                                    }}>{showPayoutDetails ? 'Hide' : 'Show'} PayPal Details

                                                    </button>
                                                }
                                                {showPayoutDetails &&
                                                    <div style={{
                                                        marginTop: '10px',
                                                        padding: '10px',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '5px',
                                                    }}>
                                                        <p>Phone: <strong>{item['users_phone']}</strong></p>
                                                        <p>Email: {item['users_email']}</p>
                                                        <p>Amount: <strong>${campaign['cash_value']}</strong></p>
                                                        <div style={{
                                                            backgroundColor: '#87CEFA44',
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                        }}>
                                                            Daisy: Boost Reward.<br/>
                                                            Thank you for participating in the campaign!
                                                        </div>

                                                        {hasPaypalId && < div
                                                            style={{
                                                                marginTop: '10px',
                                                                padding: '10px',
                                                                borderRadius: '5px',
                                                                backgroundColor: 'rgba(0, 255, 0, 0.1)',

                                                            }}>
                                                            <h3>Beta: Send Payment</h3>
                                                            {isSendingPayment ? <div>
                                                                    <p>Sending Payment...</p>
                                                                    <CircularProgress/>
                                                                </div> :
                                                                <button
                                                                    style={{
                                                                        marginTop: '10px',
                                                                        marginBottom: '10px',
                                                                        backgroundColor: 'green',
                                                                        color: 'white',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '5px',
                                                                        fontWeight: 'bold',
                                                                        border: 'none',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        handleSendPayment(item);
                                                                    }}>Send Payment - & - Mark as Paid
                                                                </button>
                                                            }
                                                            <div>
                                                                <p>PayPal ID: {item['users_paypal_account_id']}</p>
                                                                <p>Email: {item['paypal']['email']}</p>
                                                                <p>Amount: ${campaign['cash_value']}</p>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                }
                                            </td>
                                            <td>

                                                {isEditingNotes ? <div>
                                                    <textarea
                                                        style={{
                                                            width: '100%',
                                                            height: '100px',
                                                            marginBottom: '10px',
                                                        }}
                                                        value={editNotesUpdtedText}
                                                        onChange={(e) => {
                                                            setEditNotesUpdtedText(e.target.value);
                                                        }
                                                        }></textarea>

                                                        <button onClick={() => {
                                                            //cancel
                                                            setEditNotesForUUID(null);

                                                        }
                                                        }>Cancel
                                                        </button>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <button onClick={() => {
                                                            //save notes
                                                            handleUpdateItemNotes(item);
                                                        }
                                                        }>Save
                                                        </button>
                                                    </div>
                                                    : <>
                                                        {item['internal_notes'] != null && item['internal_notes'].length > 0 &&
                                                            <div style={{
                                                                backgroundColor: 'yellow',
                                                                padding: '10px',
                                                                borderRadius: '5px',
                                                                marginBottom: '10px',

                                                            }}>{item['internal_notes']}</div>}
                                                        <button
                                                            style={{
                                                                fontSize: '11px',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                            onClick={() => {
                                                                //toggle
                                                                if (editNotesForUUID === item['uuid']) {
                                                                    setEditNotesForUUID(null);
                                                                    return;
                                                                }
                                                                setEditNotesForUUID(item['uuid']);
                                                                setEditNotesUpdtedText(item['internal_notes']);
                                                            }}>Edit Notes
                                                        </button>
                                                    </>
                                                }
                                            </td>
                                            <td style={{
                                                fontSize: '12px',
                                                textAlign: 'center',
                                                color: '#aaa',
                                            }}>
                                                {/*//check box*/}
                                                <input type="checkbox" checked={item['flagged_with_issue'] === 1}
                                                       onChange={(e) => {
                                                           let sendValues = {
                                                               'uuid': item['uuid'],
                                                               'flagged_with_issue': e.target.checked ? 1 : 0
                                                           };
                                                           updateBoostStatus(item['uuid'], sendValues);
                                                           item['flagged_with_issue'] = e.target.checked ? 1 : 0;
                                                           let index = boosts.findIndex((u: any) => u.uuid === item.uuid);
                                                           if (index > -1) {
                                                               let updated: any[] = [...boosts];
                                                               updated[index] = item;
                                                               setBoosts(updated);
                                                           }
                                                       }}/>

                                            </td>
                                            <td style={{
                                                fontSize: '12px',
                                                textAlign: 'center',
                                                color: '#aaa',
                                            }}>
                                                {widgetStripeArea(item, section['slug'])}
                                            </td>
                                            {/*<td style={{*/}
                                            {/*    fontSize: '12px',*/}
                                            {/*    textAlign: 'center',*/}
                                            {/*    color: '#333',*/}
                                            {/*}}>*/}
                                            {/*    {item['users_paypal_account_id'] != null && item['users_paypal_account_id'].length > 0 &&*/}
                                            {/*        <span>{item['users_paypal_account_id']}</span>}*/}
                                            {/*</td>*/}
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </DSYTable>
                            <div style={{height: '30px'}}/>
                        </div>
                    })
                }

            </DSYMainWrapperInner>
        </DSYMainWrapper>
    );
}