import {apiClient} from "./apiService";

export const analyticsFetchDashboard = async () => {
    const response = await apiClient.post('/analytics/dashboard');
    return response.data;
}

export const analyticsFinancials = async () => {
    const response = await apiClient.post('/analytics/financials');
    return response.data;
}


export const analyticsFetchStripeAccounts = async () => {
    const response = await apiClient.post('/analytics/stripe-accounts');
    return response.data;
}
