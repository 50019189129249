// export const WEBSITE_URL = 'http://M1-2.local:8889';

// export const BACKEND_API = 'http://M1-2.local:8889/__api/cms/v2';
export const BACKEND_API = 'https://xa.yapysiad.com/__api/cms/v2';
export const WEBSITE_URL = 'https://daisypay.io';
export const BACKEND_COOKIE_ID = 'DSY_CMS_TOKEN';
export const K_HIDE_SNAPCHAT = false;

export class DSYPlatforms {
    static TIKTOK = 1;
    static INSTAGRAM = 2;
    static SNAPCHAT = 3;
}


export class DSYCampaignActionTypes {
    static REPOST = 'repost';
    static LIKE_AND_COMMENT = 'like_and_comment';
    static REPOST_ON_ACCOUNT_PAGE = 'repost_on_account_page';
    static SNAP_SHARE_ASSETS_WITH_LINK = 'snap_share_assets_with_link';


}

export const friendlyPlatformName = (platform: number) => {
    switch (platform) {
        case DSYPlatforms.TIKTOK:
            return 'TikTok';
        case DSYPlatforms.INSTAGRAM:
            return 'Instagram';
        case DSYPlatforms.SNAPCHAT:
            return 'Snapchat';
        default:
            return 'Unknown';
    }
}

export const friendlyPlatformImage = (platform: number) => {
    switch (platform) {
        case DSYPlatforms.TIKTOK:
            return '/images/tiktok-color.svg';
        case DSYPlatforms.INSTAGRAM:
            return '/images/instagram-color.svg';
        case DSYPlatforms.SNAPCHAT:
            return '/images/snapchat-color.svg';
        default:
            return '/images/unknown.png';
    }
}
export const friendlyCampaignActionName = (slug: string) => {

    switch (slug) {

        case DSYCampaignActionTypes.LIKE_AND_COMMENT:
            return 'Like & Comment';
        case DSYCampaignActionTypes.REPOST:
            return 'Share to Story';
        case DSYCampaignActionTypes.SNAP_SHARE_ASSETS_WITH_LINK:
            return 'Snap: Share Assets and Link';

        case DSYCampaignActionTypes.REPOST_ON_ACCOUNT_PAGE:
            return 'Repost';
        default:
            return 'Unknown';

    }
}