import styled from "styled-components";
import {useState} from "react";
import useOnceEffect from "../../hooks/userOnceEffect";
import {analyticsFinancials} from "../../services/apiAnalyticsService";
import {formatNumber} from "../Utils";

const DivFinances = styled.div`

    float: right;
    border-radius: 12px;
    border: 1px solid #eee;
    padding: 8px;

    table {
        border-collapse: collapse;
        font-size: 10px;
        border-radius: 12px;

        td, th {
            border: 1px solid #eee;
            padding: 10px;
        }

        th {
            background-color: #f0f0f0;
        }
    }
`;


export function DSYFinancialsWidget() {

    const [financials, setFinancials] = useState<any>({});

    const fetchFinancials = async () => {
        try {
            setFinancials({});
            const data = await analyticsFinancials();
            console.log('financials', data);
            if (data.hasOwnProperty('payload')) {
                setFinancials(data['payload']);
            }

        } catch (error) {
            console.error('Error fetching :', error);
        }
    }

    useOnceEffect(() => {

        fetchFinancials();
    });


    if (financials == null || Object.keys(financials).length === 0) {
        return null;
    }

    const paypalBalance = parseFloat(financials['paypal']['available']);
    const stripeBalance = parseFloat(financials['stripe']['available']);
    return <DivFinances>
        <table>
            <thead>
            <tr>
                <th>Paypal Balance</th>
                <th>Stripe Balance</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>${formatNumber(paypalBalance)}</td>
                <td>${formatNumber(stripeBalance)}</td>
            </tr>
            </tbody>
        </table>
    </DivFinances>

}