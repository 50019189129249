import {useEffect, useState} from "react";
import {DSYForm, DSYTable} from "../../styles/GlobalStyles";
import imageCompression from "browser-image-compression";
import {updateSponsor} from "../../services/apiSponsors";


export function SponsorsEditPage({sponsor, onCloseCallback, onUpdateItem}: {
    sponsor: any,
    onCloseCallback: Function,
    onUpdateItem: Function
}) {


    let sectionsToEdit = [
        {'title': 'Name', 'input_type': 'text', 'value': '', 'name': 'name'},
        {'title': 'Description', 'input_type': 'textarea', 'value': '', 'name': 'description'},
        {'title': 'URL', 'input_type': 'text', 'value': '', 'name': 'url'},
        {'title': 'TikTok', 'input_type': 'text', 'value': '', 'name': 'handle_tiktok'},
        {'title': 'Instagram', 'input_type': 'text', 'value': '', 'name': 'handle_instagram'},
        {'title': 'Snapchat', 'input_type': 'text', 'value': '', 'name': 'handle_snapchat'},
        {'title': 'X / Twitter', 'input_type': 'text', 'value': '', 'name': 'handle_x'},
        {'title': 'YouTube', 'input_type': 'text', 'value': '', 'name': 'handle_youtube'},
        {'title': 'Image', 'input_type': 'file', 'accept_types': 'image/*', 'value': '', 'name': 'img_url'},
    ]

    const [didLoadData, setDidLoadData] = useState(false);
    const [values, setValues] = useState<any>({});

    const isNewItem = !sponsor.hasOwnProperty('uuid') || sponsor.uuid === 'new';

    useEffect(() => {
        let data: any = {};
        sectionsToEdit.forEach((section) => {
            data[section.name] = sponsor[section.name];
        });
        setValues(data);
        setDidLoadData(true);

    }, []);


    const handleImageChange = async (e: any) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {


            const options = {
                maxSizeMB: 1, // Maximum file size (in MB)
                maxWidthOrHeight: 1200, // Maximum dimensions
                // onProgress: (e) => console.log(e)
            };
            try {

                const compressedFile = await imageCompression(file, options);
                if (compressedFile) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setValues({...values, 'img_url': reader.result});
                    };
                    reader.readAsDataURL(compressedFile);

                } else {
                }
            } catch (error) {
                console.error(error);

            }

        }
    };
    const handleCancel = (e: any) => {
        e.preventDefault();
        onCloseCallback();
    }

    const handleSave = async (e: any) => {
        e.preventDefault();
        let sendValues = {...values};
        sendValues.uuid = sponsor.uuid;
        var result = await updateSponsor(sponsor.uuid, sendValues);
        console.log('saved', result);
        if (result.status === 'error') {
            alert(result.message);
        } else {
            sponsor = {...sponsor, ...values};
            onUpdateItem(sponsor);
            if (isNewItem) {
                onCloseCallback({'reload': true});
            } else {
                onCloseCallback();
            }
        }
    }

    if (!didLoadData) {
        return <>
            Loading...
        </>
    }
    return (
        <div>
            <div style={{
                display: 'flex',
                gap: 20,
                alignItems: 'center',
                marginBottom: 20
            }}>

                <img src={values.img_url} width={130} height={130} style={{
                    objectFit: 'scale-down',
                    borderRadius: '5%',
                }}/>
                <input type={'file'} accept={'image/*'} onChange={handleImageChange}/>
            </div>
            <h2> {isNewItem ? <h2>Create New Sponsor</h2> : <h2>Edit Sponsor</h2>} </h2>
            <DSYForm onSubmit={handleSave}>
                <DSYTable>
                    {sectionsToEdit.map((section, index) => {
                        let inner = null;
                        if (section.input_type === 'checkbox') {
                            inner = <input type={section.input_type} name={section.name}
                                           checked={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.checked})
                                           }}/>

                        } else if (section.input_type === 'file') {
                            return null;
                        } else if (section.input_type === 'select') {
                            if (section.name === 'single_action_kind') {
                                let options = [
                                    {'title': 'Like & Comment', 'value': 'like_and_comment'},
                                    {'title': 'Repost', 'value': 'repost'},
                                ];
                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option, index) => {
                                            let selected = values[section.name] === option.value;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.value}>{option.title}</option>
                                        })}
                                    </select>
                                </>
                            }
                        } else if (section.input_type === 'radio') {

                            if (section.name === 'platforms_id') {
                                let options = [
                                    {'title': 'TikTok', 'value': 1},
                                    {'title': 'Instagram', 'value': 2},
                                ];
                                inner = <>
                                    {options.map((option, index) => {
                                        let selected = parseInt(values[section.name], 10) === option.value;
                                        return <label key={index}>
                                            <input type={section.input_type} name={section.name} value={option.value}
                                                   checked={selected}
                                                   onChange={(e) => {
                                                       setValues({
                                                           ...values,
                                                           [section.name]: parseInt(e.target.value, 10)
                                                       })
                                                   }}/>
                                            {option.title}
                                        </label>
                                    })}
                                </>
                            } else {

                                inner = <input type={section.input_type} name={section.name}
                                               value={values[section.name]}
                                               onChange={(e) => {
                                                   setValues({...values, [section.name]: e.target.value})
                                               }}/>
                            }
                        } else if (section.input_type === 'textarea') {

                            inner = <textarea name={section.name}
                                              value={values[section.name]}
                                              onChange={(e) => {
                                                  setValues({...values, [section.name]: e.target.value})
                                              }}/>
                        } else {


                            inner = <input type={section.input_type} name={section.name}
                                           value={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.value})
                                           }}/>
                        }


                        return (
                            <tr key={index}>
                                <td>{section.title}</td>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        );
                    })}
                </DSYTable>
                <div className={'cta-area'}>
                    <a onClick={handleCancel}>Cancel</a>
                    <button type="submit">Submit</button>
                </div>
            </DSYForm>
        </div>
    );
}