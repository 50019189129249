import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, {useRef, useState} from "react";
import useOnceEffect from "../hooks/userOnceEffect";
import {DSYMainWrapper, DSYMainWrapperInner, DSYSearchBar, DSYTable} from "../styles/GlobalStyles";
import {friendlyDate, tableSortIconHeaderArrow2} from "../components/Utils";
import {SponsorsEditPage} from "./subsection/SponsorsEditPage";
import {fetchBoosts} from "../services/apiBoosts";
import {friendlyCampaignActionName, friendlyPlatformName} from "../config/consts";
import {useNavigate} from "react-router-dom";
import useSortableData from "../hooks/useSortableData";

export function BoostsPage() {
    const [didLoad, setDidLoad] = useState(false);
    const [boosts, setBoosts] = useState<any>([]);
    const [selectedBoosts, setSelectedBoosts] = useState<any>({});
    const [filteredBoosts, setFilteredBoosts] = useState<any>([]);
    const searchRef = useRef<HTMLInputElement>(null);
    const [sortBy, setSortBy] = useState('date_start_desc');
    const navigate = useNavigate();

    const getItems = async () => {
        try {
            const data = await fetchBoosts();
            setBoosts([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setBoosts(items);
                setFilteredBoosts(items);
                // sortItems(sortBy, items);
            }
            setDidLoad(true);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    useOnceEffect(() => {
        getItems();
    });


    const fullScreen = false;
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: object, reason: String) => {
        console.log("reason: " + reason);
        if (reason === 'backdropClick') {
            // return;
        }
        setOpen(false);
    };

    const hanleOnCloseRequest = (e: any) => {
        console.log("hanleOnCloseRequest");
        console.log(e);
        setOpen(false);
    }

    const handleSearch = () => {
        let search = searchRef.current?.value;
        filterItems(search);
    }
    const handleUpdateItem = (item: any) => {

        //update user
        //updaet the user in the list
        //find user and update
        let index = boosts.findIndex((u: any) => u.uuid === item.uuid);
        if (index > -1) {
            let updated: any[] = [...boosts];
            updated[index] = item;
            setBoosts(updated);
            setFilteredBoosts(updated);
        } else {
            //reload page
            getItems();
        }
    }

    const filterItems = (search?: string) => {
        if (!search) {
            // sortItems(sortBy, boosts);
            setFilteredBoosts(boosts);
            return;
        }
        let term = search.toLowerCase();
        let fieldsToSearch = ['name', 'internal_name', 'uuid'];
        let filtered = boosts.filter((user: any) => {
            if (user != null) {
                let match = false;
                fieldsToSearch.forEach((field) => {
                    if (user[field]) {
                        if (user[field].toLowerCase().includes(term)) {
                            match = true;
                        }
                    }
                });
                if (match) {
                    return true;
                }
            }
            return false;
        });
        //sort
        // sortItems(sortBy, filtered);
        setFilteredBoosts(filtered);
    }


    const handleSortByClick = (field: string) => {
        if (sortBy === field) {
            setSortBy(field + '_desc');
        } else {
            setSortBy(field);
        }
        filterItems();
    }


    const {sortedData, sortConfig, requestSort} = useSortableData(filteredBoosts, {
        key: 'date_start',
        direction: 'desc'
    });

    // const {filteredData, query, setQuery} = useSearch(sortedData, '', ['name']);

    return (
        <DSYMainWrapper>
            <DSYMainWrapperInner>
                <h2>Boosts</h2>
                <DSYSearchBar>
                    <input type="text" ref={searchRef} placeholder="Search..." onKeyUp={handleSearch}/>
                </DSYSearchBar>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    // fullWidth={true}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        {selectedBoosts && <SponsorsEditPage sponsor={selectedBoosts}
                                                             onUpdateItem={handleUpdateItem}
                                                             onCloseCallback={hanleOnCloseRequest}/>}
                    </DialogContent>
                </Dialog>

                {!didLoad ? <h3>Loading...</h3> : <>
                    <DSYTable style={{
                        margin: '0',
                        width: 'auto',
                    }}>
                        <thead>
                        <tr>{
                            [
                                {'title': 'Options', 'field': 'options', 'sort': false},
                                {'title': 'Name', 'field': 'name', 'sort': true},
                                {'title': 'Platform', 'field': 'platforms_id', 'sort': true},
                                {'title': 'Action', 'field': 'single_action_kind', 'sort': true},
                                {'title': 'ID', 'field': 'uuid', 'sort': true},
                                {'title': 'Date', 'field': 'date_start', 'sort': true},
                                {'title': 'Pending', 'field': 'boost_summary_pending', 'sort': true},
                                {'title': 'Approved', 'field': 'boost_summary_approved', 'sort': true},
                                {'title': 'Rewarded', 'field': 'boost_summary_rewarded', 'sort': true},
                                {'title': 'Free', 'field': 'boost_summary_free', 'sort': true},
                                {'title': 'Issues', 'field': 'boost_summary_total_issues', 'sort': true},

                                // {'title': 'Options', 'field': 'options', 'sort': false},
                            ].map((header) => {
                                    // return renderTableHeader(header, sortBy, handleSortByClick);
                                    const isSorted = sortConfig?.key === header.field;
                                    const icon = isSorted ? tableSortIconHeaderArrow2(sortConfig?.direction) : null;
                                    return (
                                        <th
                                            style={{
                                                cursor: header.sort ? 'pointer' : 'default'
                                            }}
                                            key={header.field} className={isSorted ? 'selected' : ''}
                                            onClick={() => {
                                                if (header.sort) {
                                                    requestSort(header.field);
                                                }
                                            }}>
                                            {header.title}
                                            {icon ?? null}
                                        </th>
                                    );


                                }
                            )
                        }
                        </tr>
                        </thead>
                        <tbody>
                        {sortedData.map((item: any) => {
                            return (
                                <tr key={item['uuid']}>
                                    <td>

                                        <button onClick={() => {
                                            navigate('/boosts/' + item['uuid']);
                                        }}>View
                                        </button>
                                    </td>
                                    <td style={{
                                        fontWeight: 'bold',
                                    }}>{item['name']} <br/>
                                        {/* eslint-disable-next-line eqeqeq */}
                                        {item['internal_name'] != null && item['internal_name'].length > 1 && item['internal_name'] != 'undefined' && item['internal_name'] != 'null' &&
                                            <div style={{
                                                fontSize: '12px',
                                                color: '#666',
                                                fontWeight: 'normal',
                                            }}>{item['internal_name']}</div>}

                                    </td>
                                    <td>{friendlyPlatformName(item['platforms_id'])}</td>
                                    <td>{friendlyCampaignActionName(item['single_action_kind'])}</td>
                                    <td
                                        style={{
                                            fontSize: '10px',
                                            whiteSpace: 'nowrap',

                                        }}
                                    >{item['uuid']}</td>
                                    <td style={{
                                        maxWidth: '200px',
                                        minWidth: '200px',
                                        whiteSpace: 'wrap',
                                        wordBreak: 'break-all',
                                    }}>{friendlyDate(item['date_start'], 'LL')}</td>
                                    <td
                                        style={{
                                            backgroundColor: item['boost_summary_pending'] > 0 ? 'yellow' : 'inherit',
                                        }}
                                    >{item['boost_summary_pending']}</td>
                                    <td style={{
                                        backgroundColor: item['boost_summary_approved'] > 0 ? 'lightgreen' : 'inherit',
                                    }}>{item['boost_summary_approved']}</td>
                                    <td>{item['boost_summary_rewarded']}</td>
                                    <td>{item['boost_summary_free']}</td>
                                    <td style={{
                                        backgroundColor: item['boost_summary_total_issues'] > 0 ? 'lightcoral' : 'inherit',
                                    }}>{item['boost_summary_total_issues']}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </DSYTable>
                </>}
            </DSYMainWrapperInner>
        </DSYMainWrapper>
    );
}