import {apiClient} from "./apiService";

export const fetchCampaigns = async () => {
    const response = await apiClient.get('/campaigns/all');
    return response.data;
};

export const fetchCampaignById = async (id: string) => {
    const response = await apiClient.post(`/campaigns/uuid/${id}`, {'uuid': id});
    return response.data;
};

export const createCampaign = async (userData: any) => {
    const response = await apiClient.post('/campaigns', userData);
    return response.data;
};

export const updateCampaign = async (id: string, formData: FormData) => {

    //detect certain keys and add them for form data to post
    // const listOfKeys = ['snap_video'];
    // const formData = new FormData();
    // Object.keys(userData).forEach(key => {
    //     if (listOfKeys.includes(key)) {
    //         formData.append(key, userData[key]);
    //     }
    // });
    // //add userDate to form data
    // formData.append('userData', JSON.stringify(userData));
    // const response = await apiClient.post(`/campaigns/update/${id}`, formData);
    // return response.data;

    const response = await apiClient.postForm(`/campaigns/update/${id}`, formData);
    return response.data;

    //
    // const response = await apiClient.post(`/campaigns/update/${id}`, userData);
    // return response.data;
};

export const usersSelectedForCampaignId = async (id: string, data: any) => {
    const response = await apiClient.post(`/campaigns/users-selected/${id}`, data);
    return response.data;
};
export const usersUpdateSelectedForCampaignId = async (id: string, data: any) => {
    const response = await apiClient.post(`/campaigns/users-selected-update/${id}`, data);
    return response.data;
};


export const updateCampaignProperties = async (id: string, keyValue: object) => {
    const response = await apiClient.post(`/campaigns/update-properties/`, {
        'uuid': id,
        'keyValue': keyValue
    });
    return response.data;
}

export const createShortLinkForCustomUrl = async (id: string, url: string) => {
    const response = await apiClient.post(`/campaigns/generate-short-link-for-custom-url/`, {
        'uuid': id,
        'url': url
    });
    return response.data;
}